<template>
    <div style="width:100%;height:100%;">
        <!-- Quota Data Card -->
        <div v-if="type.toLowerCase() == 'quota'" style="width:100%;height:100%;">
            <dc-quota :elevation="0" :cssClass="cssClass" :title="title" :backgroundColor="backgroundColor" ref="dc"></dc-quota>
        </div>

        <!-- Units Data Card -->
        <div v-else-if="type.toLowerCase() == 'units'" style="width:100%;height:100%;">
            <dc-units :elevation="0" :cssClass="cssClass" :title="title" :backgroundColor="backgroundColor" ref="dc"></dc-units>
        </div>

        <!-- Units Data Card -->
        <div v-else-if="type.toLowerCase() == 'residents'" style="width:100%;height:100%;">
            <dc-residents :elevation="0" :cssClass="cssClass" :title="title" :backgroundColor="backgroundColor" ref="dc"></dc-residents>
        </div>

        <div v-else style="width:100%;height:100%;">
            <v-card width="100%" height="100%" :class="cssClass" :elevation="elevation" :color="backgroundColor"> <!-- color="light-blue darken-2" -->
                <v-row class="fill-height" align="center" justify="center" no-gutters>
                    <v-col cols="12" class="text-center">
                        <v-avatar size="80">
                            <v-icon size="80" color="grey lighten-1">mdi-plus-circle-outline</v-icon>
                        </v-avatar>
                        <p class="subtitle-2 grey--text text--darken-1">{{ undefinedTypeLabel }}</p>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </div>
</template>

<script>
import DcQuota from '@/components/DcQuota.vue';
import DcUnits from '@/components/DcUnits.vue';
import DcResidents from '@/components/DcResidents.vue';

export default {
    props: {
        type: {
            type: String,
            default: ''
        },
        elevation: {
            type: Number,
            default: 0
        },
        backgroundColor: {
            type: String,
            default: 'white'
        },
        cssClass: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        undefinedTypeLabel: {
            type: String,
            default: 'Data card not defined'
        }
    },
    data: () => ({
        quotaSparklineLabels:   [ 'Dic', 'Jan', 'Feb', 'Mar', 'Apr','May','Jun','Jul','Aug','Sep','Oct','Nov', ],
        quotaSparklineValues:   [ 200, 675, 410, 390, 310, 460, 250, 240 ,410, 390, 250, 240 ],
    }),
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
            // this.$refs.dc.load();
        },
    },
    components: {
        DcUnits, DcQuota, DcResidents
    }
}
</script>

<style scoped>

</style>
<template>
    <v-skeleton-loader type="article, actions" style="height:100%;" :loading="!loaded"> <!--  transition="fade-transition" -->
        <v-card width="100%" height="100%" :class="cssClass" :elevation="elevation" :color="backgroundColor"> <!-- color="light-blue darken-2" -->
            <v-card-title>
                {{ title.length == 0 ? 'Units': title }}
                <!-- <v-spacer></v-spacer>
                <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn> -->
            </v-card-title>
            <v-card-subtitle>
                <span class="caption">Shows total units in the community segmented by unit type</span>
            </v-card-subtitle>
            <v-card-text>
                <v-row class="fill-height" align="center">
                    <v-col cols="6">
                        <div class="text-center">
                            <div class="grey--text text--darken-2 display-2">{{ totalHouses }}</div>
                            <div class="grey--text text--darken-5 body-1">{{ housesLabel }}</div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-center">
                            <div class="grey--text text--darken-2 display-2">{{ totalApartments }}</div>
                            <div class="grey--text text--darken-5 body-1">{{ apartmentsLabel }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-skeleton-loader>
</template>

<script>
import { API } from '@/inc/api';

export default {
    props: {
        elevation: {
            type: Number,
            default: 0
        },
        backgroundColor: {
            type: String,
            default: 'white'
        },
        cssClass: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        loaded: false,
        totalHouses: 0,
        totalApartments: 0,
    }),
    computed: {
        housesLabel() {
            return this.totalHouses == 1 ? 'House' : 'Houses';
        },
        apartmentsLabel() {
            return this.totalApartments == 1 ? 'Apartment' : 'Apartments';
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            // Load units count
            let res = await api.getDcUnits();
            this.loaded = true;
            if( !res.data.status && res.data.error ) {
                console.log('ERROR: ' + res.data.message );
                return;
            }
            
            this.totalHouses = res.data.data.find(u => {
                return u.buildingTypeId == 1;
            }).unitQty;

            this.totalApartments = res.data.data.find(u => {
                return u.buildingTypeId == 2;
            }).unitQty;
        },
    },
}
</script>

<style>

</style>
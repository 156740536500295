<template>
    <v-skeleton-loader type="article, actions" style="height:100%;" :loading="!loaded"> <!--  transition="fade-transition" -->
        <v-card width="100%" height="100%" :class="cssClass" :elevation="elevation" :color="backgroundColor"> <!-- color="light-blue darken-2" -->
            <v-card-title>
                {{ title.length == 0 ? 'Total Residents': title }}
                <!-- <v-spacer></v-spacer>
                <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn> -->
            </v-card-title>
            <v-card-subtitle>
                <span class="caption">Shows how many residents are reachable in the community</span>
            </v-card-subtitle>
            <v-card-text>
                <div class="text-center">
                    <div class="grey--text text--darken-2 display-2">{{ totalResidents }}</div>
                </div>
            </v-card-text>
        </v-card>
    </v-skeleton-loader>
</template>

<script>
import { API } from '@/inc/api';

export default {
    props: {
        elevation: {
            type: Number,
            default: 0
        },
        backgroundColor: {
            type: String,
            default: 'white'
        },
        cssClass: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        loaded: false,
        totalResidents: 0,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            let res = await api.getDcResidents();
            this.loaded = true;
            if( !res.data.status && res.data.error ) {
                console.log('ERROR: ' + res.data.message );
                return;
            }
            
            this.totalResidents = res.data.totalResidents == null ? 0 : res.data.totalResidents;
        }
    },
}
</script>

<style>

</style>
<template>
    <v-skeleton-loader type="article, actions" style="height:100%;" :loading="!loaded"> <!--  transition="fade-transition" -->
        <v-card width="100%" height="100%" :class="cssClass" :elevation="elevation" :color="backgroundColor"> <!-- color="light-blue darken-2" -->
            <v-card-title>
                {{ title.length == 0 ? 'Quota Collected': title }}
                <!-- <v-spacer></v-spacer>
                <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn> -->
            </v-card-title>
            <v-card-subtitle>
                <span class="caption">Shows the total amount of quota collected for the parameter selected</span>
            </v-card-subtitle>
            <v-card-text>
                <div class="text-right">
                    <span class="display-1">{{ formatMoney(collected) }}</span> / {{ formatMoney(goal) }}
                </div>

                <v-progress-linear v-model="progress" color="blue-grey" height="5" rounded>
                </v-progress-linear>

                <!-- <v-sheet class="v-sheet--offset mx-auto mt-2" color="cyan" elevation="0">
                    <v-sparkline :labels="quotaSparklineLabels" :value="quotaSparklineValues" color="white" line-width="2" padding="16"></v-sparkline>
                </v-sheet> -->
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <span class="caption grey--text">Showing data for:</span>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{ on }">
                        <v-btn text v-on="on" small>{{ timeFrame }}</v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="tf in timeFrames" :key="tf.id" @click="setTimeFrame(tf.text)">
                            <v-list-item-title>{{ tf.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-actions>
        </v-card>
    </v-skeleton-loader>
</template>

<script>
import { API } from '@/inc/api';

export default {
    props: {
        elevation: {
            type: Number,
            default: 0
        },
        backgroundColor: {
            type: String,
            default: 'white'
        },
        cssClass: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        loaded: false,
        timeFrames: [
            { id: 0, text: 'This Month' },
            { id: 1, text: 'Last Month' },
            { id: 2, text: 'This Year' },
            { id: 3, text: 'Last Year' },
        ],
        timeFrame: 'this month',
        collected: 0,
        goal: 0,
        progress: 0,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loaded = false;
            let api = new API();

            // Load collected quota
            let res = await api.getDcQuota( this.timeFrame.toLowerCase() );
            this.loaded = true;

            if( !res.data.status && res.data.error ) {
                console.log('ERROR: ' + res.data.message );
                return;
            }

            this.collected = res.data.data.collected;
            this.goal = res.data.data.goal;
            this.progress = ( this.collected / this.goal ) * 100;
        },
        setTimeFrame(tf) {
            this.timeFrame = tf;
            this.init();
        }
    },
}
</script>

<style>
    
</style>